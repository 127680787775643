<template>
  <v-sheet
    v-bind="$attrs"
    :class="['d-flex', 'flex-wrap', 'sections', `${safeVariant}-sections`, styles]"
  >
    <slot>
      <mtf-section
        v-for="(section, index) in sections"
        :key="`section-${index}`"
        v-bind="section"
      />
    </slot>
  </v-sheet>
</template>

<script>
export default defineComponent({
  name: 'MtfSections',

  props: {
    styles: [String, Object, Array],
    sections: Array,
    variant: String
  },
  computed: {
    safeVariant() {
      return this.variant || 'main';
    }
  },
  methods: {}
});
</script>
